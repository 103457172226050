/*お問い合わせ*/
.contact-page {
  max-width: 900px;
  margin: 40px auto;
  margin-top: 200px;
  padding: 20px;
  border-radius: 8px;
  height: 250vh;
}

.contact-title {
  font-size: 25px;
  color: #333;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 300;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/*問い合わせフォーム*/
.contact-form {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 100px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.required {
  color: #ff4d4d;
  font-size: 0.7em;
  margin-left: 5px;
  background-color: #fff3f3;
  padding: 2px 6px;
  border: 1px solid #ff4d4d;
  display: inline-block;
  font-weight: bold;
}

.contact-form label {
  font-size: 16px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea .contact-form select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #999;
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-form select {
  appearance: none;
  background: #fff;
  background-size: 16px 16px;
  line-height: 3;
}
.contact-privacy-policy {
  margin: 10px 0;
  align-content: center;
}
.contact-privacy-policy input {
  margin-right: 10px;
}

.contact-privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

/*送信ボタン*/
.contact-form button {
  padding: 12px;
  background-color: #36ba98;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.contact-form button:hover:not(:disabled) {
  background-color: #219c90;
}

.contact-details {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.contact-details i {
  margin-right: 10px;
  color: #006eb9;
}
.contact-details p {
  margin: 10px 0;
  font-size: 16px;
}

.contact-details a {
  color: #007bff;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* ポップアップ */
.custom-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: #31363f;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  max-width: 500px;
  text-align: left;
  animation: fadeInOut 5s ease-in-out;
  font-family: Arial, sans-serif;
}
/* 送信成功時 */
.custom-alert.success {
  background-color: #ffffff;
  color: #31363f;
}
/* 送信失敗時*/
.custom-alert.error {
  background-color: #f44336;
  color: #ffffff;
}

.custom-alert h2 {
  margin-top: 0;
  font-size: 20px;
}

.custom-alert .alert-content {
  margin-top: 10px;
}

.custom-alert .alert-content div {
  margin-bottom: 10px;
}

.custom-alert .alert-content div strong {
  display: block;
  font-weight: bold;
}

.custom-alert .alert-content div span {
  display: block;
  font-size: 16px;
}

.custom-alert button {
  display: block;
  margin: 20px auto 0;
  background-color: #67c6e3;
  color: #fff;
  border: 1px solid #67c6e3;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-alert button:hover {
  background-color: #ffffff;
  color: #67c6e3;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
  10%,
  90% {
    opacity: 1;
    transform: translateY(0);
  }
}
.recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
